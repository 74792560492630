.AccessNav.js-accessnav {
  background: #fff;
  display: none;
  position: absolute;
  top: -20em;
  z-index: 1202;
  width: 100%;
  display: block;
}

.AccessNav__list {
  list-style: none;
}

.AccessNav__item {
  background: #bdbdbd;
  color: #212121;
  font-weight: 500;
  padding: calc((60px - 1em) / 2) 0;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 1.5em;
}

.AccessNav__item:focus {
  top: 13em;
  text-decoration: none;
}
